import { rewriteFramesIntegration } from '@sentry/browser';
import { defineIntegration } from '@sentry/core';

export const clientStackFrameNormalizationIntegration = defineIntegration(
  ({ assetPrefixPath }: { assetPrefixPath: string }) => {
    const rewriteFramesInstance = rewriteFramesIntegration({
      // Turn `<origin>/<path>/_next/static/...` into `app:///_next/static/...`
      iteratee: frame => {
        // Ensure frame.filename is defined before proceeding
        if (frame.filename) {
          try {
            const { origin } = new URL(frame.filename);
            frame.filename = frame.filename.replace(origin, 'app://').replace(assetPrefixPath, '');
          } catch (err) {
            // Filename wasn't a properly formed URL, so there's nothing we can do
          }

          // We need to URI-decode the filename to properly handle wildcard routes like "/users/[id].js"
          if (frame.filename.startsWith('app:///_next')) {
            frame.filename = decodeURI(frame.filename);
          }

          // Filter out Next.js internal code frames
          if (
            frame.filename.match(
              /^app:\/\/\/_next\/static\/chunks\/(main-|main-app-|polyfills-|webpack-|framework-|framework\.)[0-9a-f]+\.js$/
            )
          ) {
            frame.in_app = false;
          }
        }

        return frame;
      },
    });

    return {
      ...rewriteFramesInstance,
      name: 'NextjsClientStackFrameNormalization',
    };
  }
);
