"use client";
import { ReactNode, useEffect, useMemo } from "react";
import { init, ErrorBoundary, getDefaultIntegrations as getReactDefaultIntegrations } from "@sentry/react";
import { clientStackFrameNormalizationIntegration } from "@/lib/sentry/client-stack-frame-normalization-integration";
import { applySdkMetadata, addEventProcessor } from "@sentry/core";
import type { EventProcessor } from "@sentry/types";
import { browserTracingIntegration } from "@/lib/sentry/browser-tracing-integration";
import GlobalError from "@/app/global-error";
interface SentryProviderProps {
  children: ReactNode;
}

export const SentryProvider = ({ children }: SentryProviderProps) => {
  const customDefaultIntegrations = getReactDefaultIntegrations({});

  customDefaultIntegrations.push(browserTracingIntegration());
  customDefaultIntegrations.push(clientStackFrameNormalizationIntegration({ assetPrefixPath: "/_next" }));

  const opts = useMemo(
    () => ({
      dsn: process.env.SENTRY_DSN,

      environment: process.env.NODE_ENV,

      // Adjust this value in production, or use tracesSampler for greater control
      tracesSampleRate: 1,

      // Setting this option to true will print useful information to the console while you're setting up Sentry.
      debug: false,

      replaysOnErrorSampleRate: 1.0,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      defaultIntegrations: customDefaultIntegrations,
    }),
    [customDefaultIntegrations]
  );

  useEffect(() => {
    // Only initialize Sentry if the environment is not 'development'
    if (process.env.NODE_ENV !== "development") {
      applySdkMetadata(opts, "nextjs", ["nextjs", "react"]);
      init(opts);
      const filterTransactions: EventProcessor = (event) =>
        event.type === "transaction" && event.transaction === "/404" ? null : event;
      filterTransactions.id = "NextClient404Filter";
      addEventProcessor(filterTransactions);
    }
  }, [opts]);

  return process.env.NODE_ENV !== "development" ? (
    // Only use ErrorBoundary in production or non-development environments
    <ErrorBoundary fallback={<GlobalError error={new Error("An error occurred")} />}>{children}</ErrorBoundary>
  ) : (
    // Directly render children in development mode to allow for better error visibility
    <>{children}</>
  );
};
